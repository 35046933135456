import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {
  EmbeddedAsset,
  EmbeddedEntry,
} from '../components/renderNodeComponents'
import { formatUrl } from '../../../../common/utils'
import RelatedPosts from '../../ContentTemplate/components/RelatedPosts'
import H2 from '../../Headings/H2'
import H3 from '../../Headings/H3'
import H4 from '../../Headings/H4'
import Hyperlink from '../../HyperLink'
import { toKebabCase } from '../../../../common/helpers'

export const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: EmbeddedAsset,
    [BLOCKS.EMBEDDED_ENTRY]: EmbeddedEntry,
    [INLINES.HYPERLINK]: (node, children) => {
      const path = formatUrl(node.data.uri)

      return (
        <Hyperlink isLeadCta={node.data.isLeadCta} href={path}>
          {children}
        </Hyperlink>
      )
    },
    'related-posts': ({ data: { similarPosts } }) => {
      if (!similarPosts || !similarPosts.length) return null

      return <RelatedPosts items={similarPosts} />
    },
    [BLOCKS.HEADING_2]: (node) => {
      const h2_Value = node.content[0]?.value
      const h2_id = toKebabCase(h2_Value)

      return h2_Value ? <H2 text={h2_Value} id={h2_id} /> : null
    },
    [BLOCKS.HEADING_3]: (node) => {
      const h3_Value = node.content[0].value

      return h3_Value ? <H3 text={h3_Value} /> : null
    },
    [BLOCKS.HEADING_4]: (node) => {
      const h4_Value = node.content[0].value

      return h4_Value ? <H4 text={h4_Value} /> : null
    },
  },
}
